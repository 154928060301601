import React from "react";

import Seo from "../../components/info-site/Seo";
import Layout from "../../layouts/en";
import CaseStudy from "../../components/info-site/CaseStudy";

const FoodBeverage = props => {
    return (
        <Layout light location={props.location}>
            <Seo titleId="foodCaseTitle" descriptionId="foodCaseDescription" />
            <CaseStudy
              title="foodCaseTitle"
              caseStudy="caseStudy2"
            />
        </Layout>
    );
};

export default FoodBeverage;